<template>
  <div class="animated fadeIn">
    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="m-auto">
      <v-card class="pass-card pa-5 mt-5">
        <v-card-title>
          <h3 class="mb-3">
            <v-icon>mdi-lock-open-outline</v-icon>
            تغییر رمز عبور
          </h3>
        </v-card-title>
        <v-card-text>
          <div>
            <v-text-field
              outlined
              dense
              :type="show2 ? 'text' : 'password'"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show2 = !show2"
              label="رمز عبور جدید"
              v-model="newPassword"
              autocomplete="new-password"
            ></v-text-field>

            <v-text-field
              outlined
              dense
              :type="show3 ? 'text' : 'password'"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show3 = !show3"
              label="تکرار رمز عبور"
              v-model="repeatPassword"
              @keyup.enter="
                newPassword && repeatPassword && !Busy ? changePassword() : ''
              "
              class="hide-field-details"
            ></v-text-field>
            <div class="h4 my-2 red--text" v-if="checkEqualPass">
              <v-icon small class="red--text">mdi-alert-circle-outline</v-icon>
              رمز عبور با تکرار آن تطابق ندارد.
            </div>
            <v-btn
              :disabled="!newPassword || !repeatPassword || checkEqualPass"
              :loading="Busy"
              class="primary-btn w-100 mt-4 font-weight-bold"
              @click="changePassword()"
            >
              ثبت</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      Busy: false,
      newPassword: "",
      repeatPassword: "",
      show2: false,
      show3: false,
    };
  },

  methods: {
    changePassword() {
      //NOTE editting password and send it to server
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/changePassword",
          {
            userId: this.$route.params.id,
            newPass: md5(this.newPassword),
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.Busy = false;
            this.toast("رمز عبور با موفقیت ویرایش شد.", "success");
            this.newPassword = "";
            this.repeatPassword = "";
            this.$emit("getInfo");
          } else {
            this.Busy = false;

            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;

          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },

  computed: {
    checkEqualPass() {
      if (this.repeatPassword) {
        if (this.newPassword == this.repeatPassword) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.pass-card {
  border-radius: 30px !important;
  background-color: #e6fdff !important;
  box-shadow: 0 10px 5px -10px !important;
  .v-card__title {
    justify-content: center;
    background-color: transparent !important;
  }
}
</style>
